import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
  Directive,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  GraphControlTypeEnum,
  GraphSidebarComponentControl,
  GraphSidebarControl,
  GraphSidebarControls,
  GraphSidebarSelectControl,
} from './index';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { isSelectGroup, SelectGroup } from '../../../core/models/ui/select-option.model';
import { NgComponentOutlet, AsyncPipe, NgTemplateOutlet, NgClass } from '@angular/common';
import { CollapsiblePanelComponent } from '../../../shared/collapsible-panel/collapsible-panel.component';
import { NgbAccordionModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from '../../../shared/select/select.component';
import { SelectColorPaletteComponent } from '../../../core/color/select-color-palette/select-color-palette.component';
import { WithColonPipe } from './with-colon.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Directive({
  selector: '[bxGraphSidebarHeader]',
  standalone: true,
})
export class GraphSidebarHeaderDirective {
  public templateRef = inject(TemplateRef);
}

type GraphSidebarControlWithSection = GraphSidebarControl & { section: string };

interface GraphSidebarControlsWithSection {
  section: string;
  controls: GraphSidebarControlWithSection[];
}

@Component({
  selector: 'bx-graph-sidebar',
  templateUrl: './graph-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./graph-sidebar.component.scss'],
  standalone: true,
  imports: [
    CollapsiblePanelComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltip,
    NgComponentOutlet,
    SelectComponent,
    SelectColorPaletteComponent,
    AsyncPipe,
    WithColonPipe,
    NgTemplateOutlet,
    NgbAccordionModule,
    FontAwesomeModule,
    NgClass,
  ],
})
export class GraphSidebarComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding('class') readonly hostClass = 'd-flex h-100';
  headerTemplate = contentChild(GraphSidebarHeaderDirective, { descendants: true });
  @Input() controls: GraphSidebarControls;
  // Hides the entire sidebar
  @Input() hideControls = false;
  // Hides all controls created by this component (i.e. the ones specified by
  // this.controls), but does not hide controls passed as child components.
  // For instance, it will not hide the graph selector used by the ngs-graphs viewer.
  // Useful when the controls input may be invalid, but we don't want to update it yet.
  @Input() hideGeneratedControls = false;
  @Input() allowControlUpdates = false;
  @Input() updateControlsAfterFirstChange = false;
  @Output() controlsChanged = new EventEmitter<any>();
  @Output() toggle = new EventEmitter<boolean>();

  form: FormGroup<{ [k: string]: FormControl<string | number | boolean | string[]> }> =
    new FormGroup({});
  /** For generating unique input/label IDs */
  readonly idPrefix = 'graphSidebar_';
  readonly ControlType = GraphControlTypeEnum;

  private subscription = new Subscription();
  protected controlsBySection$ = new BehaviorSubject<GraphSidebarControlsWithSection[]>([]);

  ngOnInit() {
    this.initControls(this.controls.filter((control) => !control.hidden));
  }

  ngOnChanges({ controls }: SimpleChanges) {
    const currentValue = controls?.currentValue?.filter(
      (ctrl: GraphSidebarControl) => !ctrl.hidden,
    );
    const previousValue = controls?.previousValue?.filter(
      (ctrl: GraphSidebarControl) => !ctrl.hidden,
    );
    const controlOrControlValuesChanged =
      currentValue?.length !== previousValue?.length ||
      currentValue
        .filter((ctrl: GraphSidebarControl) => !ctrl.hidden)
        .some((ctrl: GraphSidebarControl) => {
          const previousControl: GraphSidebarControl = previousValue?.find(
            (c: GraphSidebarControl) =>
              c?.name === ctrl?.name &&
              c?.type === ctrl?.type &&
              c?.disabled === ctrl?.disabled &&
              c?.label === ctrl?.label &&
              c?.tooltip === ctrl?.tooltip,
          );
          if (!previousControl) {
            return true;
          }
          switch (ctrl.type) {
            case GraphControlTypeEnum.CHECKBOX:
            case GraphControlTypeEnum.INPUT:
              return false;
            case GraphControlTypeEnum.PALETTE:
              const previousPalette = previousControl as typeof ctrl;
              return (
                ctrl.isCategorical !== previousPalette.isCategorical ||
                ctrl.numCategories !== previousPalette.numCategories
              );
            case GraphControlTypeEnum.SELECT:
              const pCtrl = previousControl as typeof ctrl;
              if (ctrl.options.length !== pCtrl.options.length) {
                return true;
              }
              const [oldOpts, newOpts] = [pCtrl, ctrl].map((control) =>
                control.options
                  .map((option) => {
                    if (isSelectGroup(option)) {
                      return (option as SelectGroup<string | number>).options.flat();
                    } else {
                      return [option];
                    }
                  })
                  .flat(),
              );
              if (oldOpts.length !== newOpts.length) {
                return true;
              }
              return newOpts.some((newOption, i) => {
                return (
                  newOption?.displayName !== oldOpts[i]?.displayName ||
                  newOption?.value !== oldOpts[i]?.value
                );
              });
            default:
              return false;
          }
        });
    if (
      this.allowControlUpdates &&
      ((this.updateControlsAfterFirstChange && !controls.isFirstChange()) ||
        controlOrControlValuesChanged)
    ) {
      this.form = new FormGroup({});
      this.initControls(currentValue);
    }
  }

  initControls(controls: GraphSidebarControls) {
    controls.forEach((control: GraphSidebarControl) => {
      const formControl = new FormControl({
        value: control.defaultOption,
        disabled: control.disabled,
      });
      this.form.addControl(control.name, formControl);
    });
    const controlsList = controls.map((control) => ({
      ...control,
      options:
        control.type === GraphControlTypeEnum.SELECT
          ? [...(control as Pick<GraphSidebarSelectControl, 'options'>).options]
          : undefined,
      injectorWithForm:
        control.type === GraphControlTypeEnum.COMPONENT
          ? (control as GraphSidebarComponentControl).injector(
              this.form.get(control.name) as FormControl,
            )
          : undefined,
    }));

    const controlsBySection: GraphSidebarControlsWithSection[] = Object.entries(
      controlsList.reduce((acc: Record<string, GraphSidebarControl[]>, control) => {
        if (!control) {
          return acc;
        }
        const section = this.getSectionNameOrMain(control?.section);
        return {
          ...acc,
          [section]:
            section in acc
              ? [...acc[section], control as GraphSidebarControl]
              : [control as GraphSidebarControl],
        };
      }, {}),
    )
      .map(([section, controls]) => ({ section, controls }) as GraphSidebarControlsWithSection)
      .sort((a, b) => (a.section === 'Main' ? -1 : b.section === 'Main' ? 1 : 0));

    this.controlsBySection$.next(controlsBySection);
    this.subscription?.unsubscribe();

    this.subscription = this.form.valueChanges
      .pipe(filter((value) => Object.keys(value).length > 0))
      .subscribe((value) => {
        this.controlsChanged.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  controlNameTracking(control: GraphSidebarControl) {
    return `${control.name} ${control?.disabled ?? false}`;
  }

  sectionNameTracking(section: { section: string; controls: GraphSidebarControls }) {
    return section.section + ',' + section.controls.map((control) => control.name).join(',');
  }

  getSectionNameOrMain(sectionName: string | null): string {
    if (!sectionName || sectionName.length === 0) {
      return 'Main';
    }
    return sectionName;
  }
}
