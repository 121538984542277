@if (!hideControls) {
  <bx-collapsible-panel
    panelClass="w-static-3 p-0 overflow-auto"
    (collapseChange)="toggle.next(!$event)"
  >
    <form [formGroup]="form">
      <div
        ngbAccordion
        class="bx-card-accordion border-top"
        [destroyOnHide]="false"
        #accordion="ngbAccordion"
      >
        @for (
          controlsForSection of controlsBySection$ | async;
          track sectionNameTracking(controlsForSection);
          let i = $index
        ) {
          <div
            [ngbAccordionItem]="idPrefix + 'panel-' + i"
            [collapsed]="controlsForSection.section !== 'Main'"
          >
            <div ngbAccordionHeader>
              <button
                type="button"
                class="btn btn-link w-100 d-flex align-items-center text-body text-decoration-none bg-light rounded-0 border-bottom"
                ngbAccordionToggle
              >
                <span>{{ controlsForSection.section }}</span>
                <fa-icon
                  class="ms-auto small"
                  [icon]="
                    accordion.isExpanded(idPrefix + 'panel-' + i) ? 'chevron-down' : 'chevron-right'
                  "
                ></fa-icon>
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  @if (controlsForSection.section === 'Main') {
                    @if (headerTemplate()?.templateRef) {
                      <ng-container *ngTemplateOutlet="headerTemplate().templateRef"></ng-container>
                    }
                  }
                  @for (
                    control of controlsForSection.controls;
                    track controlNameTracking(control)
                  ) {
                    <ng-container
                      [ngTemplateOutlet]="sidebarControl"
                      [ngTemplateOutletContext]="{ control: control }"
                    >
                    </ng-container>
                  }
                </ng-template>
              </div>
            </div>
          </div>
        }
      </div>
    </form>
  </bx-collapsible-panel>
}

<ng-template #sidebarControl let-control="control">
  <div
    class="row mb-3"
    [ngClass]="{ 'form-check': control.type === ControlType.CHECKBOX }"
    [ngbTooltip]="control.tooltip"
    [class.opacity-50]="control.disabled"
  >
    @switch (control.type) {
      @case (ControlType.COMPONENT) {
        @if (!control.layout || control.layout === 'inline') {
          <label class="col-4 col-form-label">{{ control.label | withColon }}</label>
          <div class="col-8">
            <ng-container
              *ngComponentOutlet="control.component; injector: control.injectorWithForm"
            >
            </ng-container>
          </div>
        } @else {
          <label class="form-label hidden-if-empty">{{ control.label | withColon }}</label>
          <ng-container *ngComponentOutlet="control.component; injector: control.injectorWithForm">
          </ng-container>
        }
      }
      @case (ControlType.SELECT) {
        @if (!control.layout || control.layout === 'inline') {
          <label class="col-4 col-form-label" [for]="idPrefix + control.name">{{
            control.label | withColon
          }}</label>
          <div class="col-8">
            <bx-select
              [id]="idPrefix + control.name"
              [formControl]="form.get(control.name)"
              [selectOptions]="control.options"
              [smallSize]="false"
            >
            </bx-select>
          </div>
        } @else {
          <label class="form-label" [for]="idPrefix + control.name">{{
            control.label | withColon
          }}</label>
          <bx-select
            [id]="idPrefix + control.name"
            [formControl]="form.get(control.name)"
            [selectOptions]="control.options"
            [smallSize]="false"
          >
          </bx-select>
        }
      }
      @case (ControlType.CHECKBOX) {
        <div class="col-4">
          <input
            class="form-check-input"
            type="checkbox"
            [id]="idPrefix + control.name"
            [name]="control.name"
            [formControl]="form.get(control.name)"
            [attr.disabled]="control.disabled ? true : null"
          />
        </div>
        <label [for]="idPrefix + control.name" class="col-8 form-check-label">
          {{ control.label }}
        </label>
      }
      @case (ControlType.INPUT) {
        @if (!control.layout || control.layout === 'inline') {
          <label class="col-8 col-form-label" [for]="idPrefix + control.name">{{
            control.label | withColon
          }}</label>
          <div class="col">
            <input
              class="form-control ms-auto"
              [id]="idPrefix + control.name"
              [type]="control.valueType"
              [name]="control.name"
              [formControl]="form.get(control.name)"
            />
          </div>
        } @else {
          <div>
            <label class="form-label" [for]="idPrefix + control.name">{{
              control.label | withColon
            }}</label>
            <input
              class="form-control"
              [id]="idPrefix + control.name"
              [type]="control.valueType"
              [name]="control.name"
              [formControl]="form.get(control.name)"
            />
          </div>
        }
      }
      @case (ControlType.PALETTE) {
        @if (!control.layout || control.layout === 'inline') {
          <label class="col-4 col-form-label" [for]="idPrefix + control.name">{{
            control.label | withColon
          }}</label>
          <div class="col-8">
            <bx-select-color-palette
              [id]="idPrefix + control.name"
              class="row"
              [formControl]="form.get(control.name)"
              [isCategorical]="control.isCategorical"
              [numCategories]="control.numCategories"
            ></bx-select-color-palette>
          </div>
        } @else {
          <label class="form-label" [for]="idPrefix + control.name">{{
            control.label | withColon
          }}</label>
          <bx-select-color-palette
            [id]="idPrefix + control.name"
            class="row"
            [formControl]="form.get(control.name)"
            [isCategorical]="control.isCategorical"
            [numCategories]="control.numCategories"
          ></bx-select-color-palette>
        }
      }
    }
  </div>
</ng-template>
